@import url(https://unpkg.com/@tailwindcss/typography@0.4.x/dist/typography.min.css);
@import url(https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css);
@import url(https://fonts.google.com/specimen/Montserrat);
.name {
  font-family: "Montserrat";
  font-weight:  700;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 150px;
}

.card {
  text-align: center;
  perspective: 600px;
  /* position: absolute; */
  /* transform-style: preserve-3d; */
  /* transition: transform 0.5s ease-in-out 0s; */
  /* transform: rotateY(0deg); */

}

.card-body {
  transform-style: preserve-3d;
  transition: transform 0.5s ease-in-out 0s;
  /* width: 9rem; */
  /* height: 9rem; */
}

.card:hover .card-body {
  transform: rotateY(180deg);
  /* transition: transform 0.5s ease-in-out 0s; */
  /* transform-style: preserve-3d; */
  /* position: absolute; */
}

.card-front, .card-back {
  /* position: absolute; */
  /* top: 0px; */
  /* left: 0px; */
  /* height: 9rem; */
  /* width: 9rem; */
  /* line-height: 150px; */
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background-color: rgb(253, 230, 138);
  border: 6px solid rgb(30, 58, 138);
  /* box-shadow: 6px 6px lightseagreen; */
}
.card-front {
  transform: rotateY(0deg);
}
.card-back {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  transform: rotateY(180deg);
  /* text-align: center; */
  /* display: inline-block; */
  /* vertical-align: middle; */
  /* box-shadow: 6px 6px lightcoral; */
  /* font-size: 30px; */
}

.scroll {
  /* font-size: 20px; */
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
}

